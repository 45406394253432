import React, { Component } from 'react';
import VariableGraph  from "./VariableGraph";
import '../../styles/graphs.css';
import Container from 'react-bootstrap/Container';
import API from "../api/api";
import addDays from 'date-fns/addDays';
import format from 'date-fns/format';
import BarComponent from "../userbar/BarComponent";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import history from '../history/history';

import "../../styles/loading.css";
import LoadingComponent from "../loading/LoadingComponent";
import {GRAPHS_LOADING_MESSAGE} from "../utils/Constants";

class VariableGraphContainer extends Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            variablesData: [],
            isLoaded: false,
            sensorThresholds: JSON.parse(localStorage.getItem('actual-sensor')).threshold,
            idSensor: (localStorage.getItem('actual-sensor')) ? JSON.parse(localStorage.getItem('actual-sensor')).idSensor : null,
            showGraphs: false,
            showErrorMessage: false
        }
        this.handleShowGraphs = this.handleShowGraphs.bind(this);
        this.handleCloseErrorMessage = this.handleCloseErrorMessage.bind(this);
    }

    setThresholdForEachGraph(variablesData) {
        let tempGraph;
        const thresholds = this.state.sensorThresholds;
        for (let i = 0; i < variablesData.length; i++) {
            tempGraph = variablesData[i];
            for (let j = 0; j < thresholds.length; j++) {
                if (tempGraph.idVariable === thresholds[j].idVar) {
                    variablesData[i].threshold = {
                        max: thresholds[j].maxValue,
                        min: thresholds[j].minValue
                    }
                }
            }
        }
        return variablesData;
    }

    componentDidMount() {
        this._isMounted = true;
        let variablesData = [];
        if (this.state.idSensor !== null) {
            setTimeout(this.handleShowGraphs,1500);
            API.get('/sensors/measurements', {
                params: {
                    'idSensor': this.state.idSensor,
                    'startDate': format(addDays(new Date(), -100), "yyyy-MM-dd"), //Change the number of days to 7 before
                    'endDate': format(new Date(), "yyyy-MM-dd")
                },
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('user-jwt')
                }
            }).then((res) => {
                if(res.data.idSensor === null){
                    this.setState({showErrorMessage:true});
                }else {
                    variablesData = this.setThresholdForEachGraph(res.data.listBriefMeasures);
                    this.setState({variablesData});
                }
            }).catch(() => {

            });
        } else {
            history.push('/error');
        }
    }

    handleShowGraphs (){
        this.setState({showGraphs:true});
    }

    handleCloseErrorMessage(){
        this.setState({showErrorMessage:false});
        history.push('/sensors-view');
    }

    render() {
        const loadingProps = {
            message: GRAPHS_LOADING_MESSAGE,
            type: "success"
        }
        if (this.state.idSensor !== null && this.state.showGraphs) {
            return (
                <div>
                    <BarComponent/>
                    <Modal show={this.state.showErrorMessage} onHide={this.handleCloseErrorMessage}>
                        <Modal.Header closeButton>
                            <Modal.Title>Aviso</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>Parece que el sensor que seleccionaste no ha tenido actividad reciente (por lo menos en 100 días).
                            Si crees que no debería ser así, ponte en contacto con nosotros.</Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={this.handleCloseErrorMessage}>
                                Aceptar
                            </Button>
                        </Modal.Footer>
                    </Modal>
                    <Container fluid="xl">
                        {this.state.variablesData.map((graphData, index) => (
                            <VariableGraph key={index} values={{graphData}}/>
                        ))}
                    </Container>
                </div>
            )
        } else
            return (
                <div>
                    <BarComponent/>
                    <LoadingComponent data={loadingProps}/>
                </div>
            );


    }
}

export default VariableGraphContainer;
