import React,{Component} from 'react';
import format from 'date-fns/format';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import notifications_image from '../../img/notifications_2.png';
import profile_icon from '../../img/user.png';
import NavDropdown from "react-bootstrap/NavDropdown";
import Dropdown from "react-bootstrap/Dropdown";
import ListGroup from "react-bootstrap/ListGroup";
import API from "../api/api";
import history from '../history/history';
import mainLogoBlanco from '../../img/Logo_blanco.png';
import '../../styles/nav.css';

class BarComponent extends Component{
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state={
            listNotifications:[],
            testing: false
        }
        this.onClickImageHandler = this.onClickImageHandler.bind(this);
    }

    componentDidMount(): void {
        if(localStorage.getItem('user-email') && !this.state.testing){
            this._isMounted = true;
            API.get('/sensors/get-alerts',{
                params: {
                    'idUser':localStorage.getItem('user-email')
                },
                headers:{
                    Authorization: 'Bearer '+localStorage.getItem('user-jwt')
                }
            })
                .then((res)=>{
                    this.setState({listNotifications:res.data})
                })
                .catch(()=>{
                    history.push("/error");
                })
        }

    }

    /*TODO: Update last time login attribute in user object and send it to the server and jwt token to disable it*/
    logoutHandler(){
        localStorage.clear();
        history.push('/');
    }

    onClickImageHandler(){
        history.push('/sensors-view');
    }

    /*TODO: Icon of notifications acording if it's checked or not*/
    render(){
        return(
            <Navbar bg="dark" variant="dark" className="justify-content-between" id="nav-bar">
                <Navbar.Brand onClick={this.onClickImageHandler}><img
                            src={mainLogoBlanco}
                            width="60"
                            height="35"
                            className="d-inline-block align-top"
                            alt="logo icon"
                            id="logo-vit"
                        />

                </Navbar.Brand>
                {/* <Nav className="justify-content-end"> */}
                <Nav>
                    <NavDropdown className="mr-sm-2" alignRight title={
                        <img
                            src={notifications_image}
                            width="30"
                            height="30"
                            className="d-inline-block align-top"
                            alt="logo icon"
                        />
                    } id="nav-dropdown-basic" >
                        <div className="conte">
                        {this.state.listNotifications.length!==0?
                            <ListGroup id="miList" >
                            {this.state.listNotifications.map((notification,index) => (
                                    <ListGroup.Item className="desple" key={index}>Sensor: {notification.idSensor}, Variable: {notification.variable.readableName}, Fecha: {format(new Date(notification.dateMeasure),"yyyy-MM-dd")}</ListGroup.Item>
                            ))}
                        </ListGroup>:<div>No se pudieron cargar tus notificaciones</div>
                        }
                        </div>
                    </NavDropdown>

                    <NavDropdown alignRight title={
                        <img
                            src={profile_icon}
                            width="30"
                            height="30"
                            className="d-inline-block align-top"
                            alt="profile icon"
                            id="miImage"
                        />
                    } id="nav-dropdown" >
                        <div className="conte">
                            {/*<Dropdown.Item className="desple pt-2"><i className= "fa fa-cog"/>Configuraciones</Dropdown.Item>*/}
                            <Dropdown.Item className="desple  pt-2" id="dd" onClick={this.logoutHandler} ><i className= "fa fa-sign-out"/>Salir</Dropdown.Item>
                        </div>
                    </NavDropdown>

                </Nav>

            </Navbar>
        );
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

}

export default BarComponent;