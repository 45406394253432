import React, { Component } from 'react';
import Card from 'react-bootstrap/Card';
import history from '../history/history';
import 'bootstrap/dist/css/bootstrap.css';
import '../../styles/sensorsview.css'
import img_bckg from '../../img/roses.jpg';
import API from './../api/api';
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

import editIcon from '../../img/edit_icon.png';


class SensorCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sensor: this.props.values,
            showConfig: false,
            sensor_name: '',
            sensor_description: '',
            sensor_notifications: false,
            sensor_crop:''
        }
        this.handleSaveSensor = this.handleSaveSensor.bind(this);
        this.handleShowConfig = this.handleShowConfig.bind(this);
        this.handleHideConfig = this.handleHideConfig.bind(this);
        this.updateSensor = this.updateSensor.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.checkboxChange =  this.checkboxChange.bind(this);
    }

    updateSensor(toGraph){
        if (localStorage.getItem('actual-sensor')) localStorage.setItem('actual-sensor', '');
        //update checked value
        let sensorData = this.state.sensor;
        if(toGraph) sensorData.checked = true;
        else{
            sensorData.name = this.state.sensor_name;
            sensorData.crop = this.state.sensor_crop;
            sensorData.description = this.state.sensor_description;
            sensorData.emails = this.state.sensor_notifications;
        }
        API.post('/sensors/update-sensor', sensorData,
            {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('user-jwt')
                }
            }
        ).then((res) => {
            localStorage.setItem('actual-sensor', JSON.stringify(res.data));
            if(toGraph) history.push('/graphs');
            else this.setState({sensor:res.data});
        }).catch(() => {
            history.push("/error");
        });
    }

    handleChange = (event) =>{
        this.setState({ [event.target.name]: event.target.value });
    }


    onClick = () => {
        if (!this.state.checked) {
            this.updateSensor(true);
        }else{
            localStorage.setItem('actual-sensor', JSON.stringify(this.state.sensor));
            history.push('/graphs');
        }
    };

    handleSaveSensor = () => {
        this.updateSensor(false);
        this.setState({ showConfig: false })
    }

    handleShowConfig(){
        this.setState({showConfig:true});
    }
    handleHideConfig(){
        this.setState({showConfig:false});
    }

    checkboxChange(){
        this.setState({sensor_notifications:!this.state.sensor_notifications})
    }

    render() {
        return (
            <div>
                <Card
                    bg={(this.state.sensor.checked) ? "background-color: red" : "background-color: white"}
                    border={(!this.state.sensor.checked) ? "danger" : "white"}
                    text={(!this.state.sensor.checked) ? "black" : "black"}
                    id="sensor_card" className="tarjeta_sensor">
                    <h6 className="CropType">{this.state.sensor.crop}</h6>
                    <Card.Img variant="top" src={img_bckg} onClick={this.onClick}/>
                    <h6 className="StateSensor" id={(this.state.sensor.active)?"colorStateActive":"colorStateInactive"}>{(this.state.sensor.active)?"Activo":"Inactivo"}</h6>
                    <Card.Body
                        onClick={this.onClick}>
                        <div className="TitleCardContainer">
                            <Card.Title>{this.state.sensor.name ? this.state.sensor.name : ""}</Card.Title>
                        </div>
                        {this.state.sensor.description ? this.state.sensor.description : ""}
                    </Card.Body>
                    <Card.Link onClick={this.handleShowConfig} className="linkEditIcon"><img className="editIcon" src={editIcon} alt="Edit Icon" /></Card.Link>

                    <Modal centered size="sm" className="ModalModifySensor" show={this.state.showConfig} onHide={this.handleHideConfig}>
                        <Modal.Header closeButton>
                            <Modal.Title >Modificar {this.state.sensor.currentSensorName}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>

                            <Form onChange={this.handleChange}>
                                <Form.Group controlId="exampleForm.ControlInput1">
                                    <Form.Label>Nombre del sensor</Form.Label>
                                    <Form.Control defaultValue={this.state.sensor.name} name={"sensor_name"}/>
                                </Form.Group>
                                <Form.Group controlId="exampleForm.ControlSelect1">
                                    <Form.Label>Seleccione su cultivo</Form.Label>
                                    <Form.Control as="select" name={"sensor_crop"}>
                                        <option>Flores</option>
                                        <option>Palma</option>
                                        <option>Café</option>
                                        <option>Aguacate</option>
                                        <option>Tomate</option>
                                        <option>Otro</option>
                                    </Form.Control>
                                </Form.Group>
                                <Form.Group controlId="formBasicCheckbox">
                                    <Form.Check type="checkbox" label="Recibir notificaciones" defaultChecked={this.state.sensor.emails} onChange={this.checkboxChange}/>
                                </Form.Group>
                                <Form.Group controlId="exampleForm.ControlTextarea1">
                                    <Form.Label>Descripción</Form.Label>
                                    <Form.Control as="textarea" rows="2" defaultValue={this.state.sensor.description} name={"sensor_description"}/>
                                </Form.Group>
                            </Form>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="primary" onClick={this.handleSaveSensor}>
                                Guardar
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </Card>
            </div>
        );
    }
}
export default SensorCard;