import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../styles/login.css';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

import Row from 'react-bootstrap/Row';
import Alert from 'react-bootstrap/Alert';

import mainLogo from '../../img/LogoVIT1_opt.png';

import history from '../history/history';

import API from './../api/api';


class Login extends Component {

    constructor(props) {
        super(props);
        this.state = {
            username: "",
            password: "",
            errorPassword: false,
            isPasswordShown: false
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleLogin = this.handleLogin.bind(this);
        
    }

    componentDidMount(): void {
        if (localStorage.getItem('user-email')) {
            if (localStorage.getItem('user-jwt'))
                history.push('/sensors-view');
            else localStorage.clear();
        }
    }

    handleChange(event) {
        this.setState({ [event.target.name]: event.target.value });
    }

    handleLogin(event) {
        event.preventDefault();
            API.post('/auth/login', {
                'Email': this.state.username,
                'Password': this.state.password
            })
                .then((res) => {
                    if (res.data !== null && res.data !== '') {
                        this.setState({ errorPassword: false });
                        localStorage.setItem('user-jwt', res.data.jwt);
                        history.push("/sensors-view");
                    }
                    else this.setState({ errorPassword: true });
                })
                .catch(() => {
                    this.setState({ errorPassword: true });
                });

    }

    handleCloseAlert = () => {
        this.setState({ errorPassword: false });
    };

    togglePasswordVisiblity = () => {
        const { isPasswordShown } = this.state;
        this.setState({ isPasswordShown: !isPasswordShown })
    };

    render() {
        return (
            <div className="ContainerLoginRegister">

            <Row>
            <div className="CardContainer">
                <div className="LoginContainer">
                    <img className="LogoVIT" src={mainLogo} alt="Logo VIT" />
                   
                        <h1 className="TitleLogIn"><b>Ingresa a Agroup</b></h1>

                        <Alert dismissible onClose={this.handleCloseAlert} show={this.state.errorPassword} variant='danger'>
                            Ups, tus credenciales no son las correctas. Intenta de nuevo
                        </Alert>
                        <Form className="FormLogIn" onChange={this.handleChange}>
                            <div id="contra">
                            <Form.Group>
                                <Form.Control className="InputEmail" type="email" placeholder="Email" name={"username"} />
                                
                                 <Form.Control className="InputPass" type={ this.state.isPasswordShown ? "text" : "password"} placeholder="Contraseña" name={"password"} />
                               
                                <i className={this.state.isPasswordShown ? "fa fa-eye-slash" :"fa fa-eye password-icon" }
                                             onClick={this.togglePasswordVisiblity}/> 
                            </Form.Group>
                            </div>
                               <h5>¿Olvidaste tu contraseña?</h5>
                            <Button  type="button" className="btn-block " onClick={this.handleLogin} >
                                Iniciar sesión
                            </Button>
                        </Form>

                </div>
                
                <div className="SignUpContainer">
                <h1 className="TextAside"><b>Bienvenido/a</b></h1>
                <h5>¡Si lo puedes medir, lo puedes controlar y optimizar!</h5>
               
                <Button variant="outline-light">Registrarse</Button>{' '}
                </div>
            </div>
            </Row>
            </div>
          
        );
    }

    componentWillUnmount(): void {
        if (this.state.username)
            localStorage.setItem('user-email', this.state.username);
    }
}

export default Login;