import format from 'date-fns/format';
import React, {Component} from 'react';
import {ResponsiveLine} from "@nivo/line";
import DayPicker, { DateUtils } from 'react-day-picker';
import parsingHandler from '../utils/Parsing';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import RangeSlider from 'react-bootstrap-range-slider';
import history from '../history/history';
import {MONTHS,WEEKDAYS_LONG,WEEKDAYS_SHORT,graphColors} from '../utils/GraphUtils';


import 'react-rangeslider/lib/index.css';
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';
import 'react-day-picker/lib/style.css';
import 'bootstrap/dist/css/bootstrap.css';
import '../../styles/graphs.css';

import API from './../api/api';

class VariableGraph extends Component {

    constructor(props) {
        super(props);
        this.state = {
            graphName: this.props.values.graphData.variableName,
            idVar: this.props.values.graphData.idVariable,
            idSensor: (localStorage.getItem('actual-sensor'))?JSON.parse(localStorage.getItem('actual-sensor')).idSensor:null,
            data: this.props.values.graphData.measures,
            showDatePicker: false,
            show: false,
            initialMaxTreshold: this.props.values.graphData.threshold.max,
            actualMaxThreshold: this.props.values.graphData.threshold.max,
            initialMinTreshold: this.props.values.graphData.threshold.min,
            actualMinThreshold: this.props.values.graphData.threshold.min,
            range:{
                from: undefined,
                to: undefined,
            },
            units: this.props.values.graphData.units,
            testing: true, //Added this parameter
            tick: 'every 20 days', //TODO: Define thick value based in total measures and dates,
            color: '#ffffff'
        };
        this.selectDate = this.selectDate.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.changeThresholdVisibility = this.changeThresholdVisibility.bind(this);
        this.handleDayClick = this.handleDayClick.bind(this);
        this.handleClickReset = this.handleClickReset.bind(this);
    }

    componentDidMount() {
        this.setState({color:graphColors[Math.floor(Math.random() * graphColors.length)]});
    }


    handleClickReset(){
        this.setState({range:{from:undefined,to:undefined}});
    }


    selectDate(e) {
        if(this.state.range.from !== undefined){
            e.preventDefault();
            let variablesData = [];
            API.get('/sensors/measurements',{
                params:{
                    'idSensor': this.state.idSensor,
                    'idVariable': this.state.idVar,
                    'startDate': format(this.state.range.from,"yyyy-MM-dd"),
                    'endDate': format(this.state.range.to,"yyyy-MM-dd")
                },
                headers:{
                    Authorization: 'Bearer '+localStorage.getItem('user-jwt')
                }
            }).then((res)=>{
                variablesData = res.data.listBriefMeasures;
                if(res.data !== '')
                    this.setState({data:variablesData[0].measures});
                else this.setState({data:[{'x':'2020-02-06-07:00:00','y':'0'}]}); //TODO: No data avaliable in those dates
            }).catch(()=>{
                history.push("/error");
            });
            this.changeDatePickerVisibility();
        }

    }

    handleChangeMaxTreshold = value => {
        const realValue = Number(value.target.value);
        if(realValue>this.state.actualMinThreshold)
        this.setState({
            actualMaxThreshold: realValue,
        });
    };

    handleChangeMinTreshold = value => {
        const realValue = Number(value.target.value);
        if(realValue<this.state.actualMaxThreshold)
        this.setState({
            actualMinThreshold: Number(value.target.value),
        })
    };


    changeThresholdVisibility() {
        this.setState({ show: true });
    }
    handleClose() {
        this.setState({ show: false });
    }

    handleUpdateThreshold = e => {
            const sensorData = this.getSensorUpdateData();
            e.preventDefault();
            API.post('/sensors/update-sensor', sensorData,
                {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('user-jwt')
                    }
                }
            ).then((res) => {
                localStorage.setItem('actual-sensor', JSON.stringify(res.data));
            }).catch(() => {
                history.push("/error");
            });
        this.handleClose();
    };

    getSensorUpdateData() {
        let sensorData = JSON.parse(localStorage.getItem('actual-sensor'));
        let thresholds = sensorData.threshold;
        let index = 0;
        for (let i = 0; i < thresholds.length; i++)
            if(thresholds[i].idVar===this.state.idVar) {index = i; break;}

        thresholds[index] = {
            "idVar": this.state.idVar,
            "maxValue": this.state.actualMaxThreshold,
            "minValue": this.state.actualMinThreshold
        };
        sensorData.threshold = thresholds;
        return sensorData;
    }

    changeDatePickerVisibility = () => {
        this.setState({ showDatePicker: !this.state.showDatePicker })
    };

    handleChange = (e) => {
       this.setState({ranges:[e.selection]});
    };

    handleDayClick= (day) => {
        const range = DateUtils.addDayToRange(day,this.state.range);
        this.setState({range});
    };

    render() {

        const {from,to} = this.state.range;
        const modifiers = {start:from,end:to};
            return (
                <Container id="_graph">
                    <Row id="ContainerTitleGraph">
                        <h3 id="TitleGraph">{this.state.graphName}</h3>
                        <Col id="ContainerButtonsTitle">
                            <Button id="ButtonConfImage" onClick={this.changeThresholdVisibility}> </Button>
                            <Button id="ButtonFilterImage" onClick={this.changeDatePickerVisibility}> </Button>
                        </Col>

                        <Modal
                            show={this.state.showDatePicker}
                            onHide={this.changeDatePickerVisibility}
                            centered
                        >
                            <Modal.Header closeButton>
                                <Modal.Title>Selecciona un rango de fechas para hacer el filtro</Modal.Title>
                            </Modal.Header>
                            <Modal.Body id="ModalGraph">
                                <p id="selector-date-id">
                                    {!this.state.range.from && !this.state.range.to && 'Selecciona la primera fecha.'}
                                    {this.state.range.from && !this.state.range.to && 'Selecciona la fecha final.'}
                                    {this.state.range.from &&
                                    this.state.range.to &&
                                    `Seleccionaste desde ${this.state.range.from.toLocaleDateString()} hasta
                                    ${this.state.range.to.toLocaleDateString()}`}{' '}
                                    {this.state.range.from && this.state.range.to && (
                                        <Button id="ButtonResetDate" onClick={this.handleClickReset}>Reiniciar</Button>
                                    )}
                                </p>
                                <DayPicker
                                    className="Selectable"
                                    locale="es"
                                    months={MONTHS}
                                    weekdaysLong={WEEKDAYS_LONG}
                                    weekdaysShort={WEEKDAYS_SHORT}
                                    numberOfMonths={1}
                                    selectedDays={[from, {from, to}]}
                                    modifiers={modifiers}
                                    onDayClick={this.handleDayClick}
                                />
                                <Button id="ButtonOkCalendar" variant="primary" type="button" onClick={this.selectDate}>
                                    Aplicar
                                </Button>
                            </Modal.Body>
                        </Modal>

                        <Modal
                            show={this.state.show}
                            onHide={this.handleClose}
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                        >
                            <Modal.Header closeButton>
                                <Modal.Title>Modifica tu nuevo umbral máximo y mínimo</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <h4>Valor máximo</h4><h6> Actualmente en: {this.state.initialMaxTreshold+" "+this.state.units}</h6>
                                        <RangeSlider
                                            value={this.state.actualMaxThreshold}
                                            onChange={this.handleChangeMaxTreshold}
                                        />
                                    <br/>
                                    <Form.Control readOnly type="text" placeholder="Valor máximo" value={this.state.actualMaxThreshold+" "+this.state.units} />
                                <hr />
                                <h4>Valor mínimo</h4><h6> Actualmente en: {this.state.initialMinTreshold+" "+this.state.units}</h6>
                                        <RangeSlider
                                            value={this.state.actualMinThreshold}
                                            onChange={this.handleChangeMinTreshold}
                                        />
                                    <br/>
                                    <Form.Control readOnly type="text" placeholder="Valor mínimo" value={this.state.actualMinThreshold+" "+this.state.units} />
                            </Modal.Body>
                            <Modal.Footer>
                                <Button id="cancelThresholdButton" variant="secondary" onClick={this.handleClose}>
                                    Cancelar
                                </Button>
                                <Button id="saveThresholdButton" variant="primary" onClick={this.handleUpdateThreshold}>
                                    Guardar cambios
                                </Button>
                            </Modal.Footer>
                        </Modal>
                    </Row>
                    <Row id="GraphRow">
                    <ResponsiveLine
                        colors={this.state.color}
                        enableArea={true}

                        areaBaselineValue={parsingHandler.minValue(this.state.idVar,this.state.data)}
                        areaOpacity={0.1}

                        margin={{ top: 40, right: 20, bottom: 20, left: 80 }}
                        data={parsingHandler.parseData(this.state.idVar,this.state.data)}
                        animate={true}
                        curve="linear"
                        xScale={{
                            type: 'time',
                            format: '%Y-%m-%d-%H:%M:%S',
                            precision: 'minute',

                        }}
                        xFormat="time:%Y-%m-%d"

                        yScale={{
                            type: 'linear',
                            min: 'auto',
                            max: 'auto',
                            reverse: false

                        }}
                        axisLeft={{
                            orient: 'left',
                            tickPadding: 5,
                            tickRotation: 0,
                            legend: this.state.units,
                            legendOffset: -50,
                            legendPosition: 'middle'
                        }}

                        axisBottom={{
                            orient: 'bottom',
                            tickSize: 5,
                            tickPadding: 5,
                            tickRotation: 0,
                            legend: 'Hora',
                            legendOffset: 36,
                            legendPosition: 'middle',
                            format: '%b %d',
                            tickValues: this.state.tick
                        }}
                        enablePoints={true}

                        useMesh={true}

                    />
                    </Row>
                </Container>
    );

    }
}

export default VariableGraph;