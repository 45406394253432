import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import './styles/index.css';
import Login from './components/login/Login';
import {Router, Route, Switch} from 'react-router-dom'
import history from './components/history/history';
import SensorsView from "./components/sensorsview/SensorsView";
import VariableGraphContainer from "./components/sensorgraphs/VariableGraphContainer";
import ErrorComponent from "./components/error/ErrorComponent";


class Root extends Component {

    render() {
        return (
            <Router history={history}>
                <Switch>
                    <Route exact path="/" name="Login Page" component={Login} />
                    <Route path="/sensors-view" name="Sensors Cards View" component={SensorsView} />
                    <Route path="/graphs" component={VariableGraphContainer} />
                    <Route component={ErrorComponent}/>
                </Switch>
            </Router>
        )
    }
}

ReactDOM.render(<Root/>, document.getElementById('root'));
