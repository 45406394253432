import React, { Component } from 'react';
import SensorCard from "./SensorCard";
import API from './../api/api';
import BarComponent from "../userbar/BarComponent";
import 'bootstrap/dist/css/bootstrap.css';
import history from "../history/history";
import '../../styles/sensorsview.css';
import LoadingComponent from "../loading/LoadingComponent";

import {SENSORS_LOADING_MESSAGE} from "../utils/Constants";

class SensorsView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sensors: [],
            userProps: {},
            isFetched: false,
            isError: false,
            showSensors: false,
            currentSensorName: '',
            showConfig: false
        }
    }



    _isMounted = false;
    handleShowSensors = (e) => {
        this.setState({ showSensors: true })
    };

    setCurrentSensorName = (name) => this.setState({ currentSensorName: name })

    componentDidMount() {
        this._isMounted = true;
        if (localStorage.getItem('user-email')) {
            setTimeout(this.handleShowSensors,1500);
            API.get('/sensors/sensors-from', {
                params: {
                    'idUser': localStorage.getItem('user-email')
                },
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('user-jwt')
                }
            })
                .then((res) => {
                    const sensors = res.data;
                    if (this._isMounted) {
                        this.setState({ sensors });
                        this.setState({ isFetched: true });
                    }

                }).catch((error) => {
                    console.log(error);
                    if (this._isMounted) {
                        this.setState({ isFetched: false });
                        this.setState({ isError: true });
                    }
                    history.push("/error");
                });
        } else {
            history.push("/error");
        }
    }

    render() {
        const loadingProps = {
            message:SENSORS_LOADING_MESSAGE,
            type: "primary"
        }
            if (this.state.isFetched && !this.state.isError && this.state.showSensors)
            return (
                <div className="ContainerView" id="todo" >
                <div>
                    <BarComponent />
                    <div id="tarjetasSensores">
                    {this.state.sensors.map(sensor => (
                        <SensorCard setSensorNameFunction={this.setCurrentSensorName} handleShow={this.handleShow} key={sensor.idSensor} values={sensor} />
                    ))}
                    </div>
                </div>
            </div>
           

            );
        else return <div>
            <BarComponent />
            <LoadingComponent data={loadingProps}/>
        </div>

    }

    componentWillUnmount() {
        this._isMounted = false;
        this.setState({sensors: [],
            userProps: {},
            isFetched: false,
            isError: false,
            showSensors: false,
            currentSensorName: '',
            showConfig: false});
    }

}


export default SensorsView;