class Utils {

    static minValue(idVar, data) {
        let factor;
        switch (idVar) {
            case 'Rd':
            case 'Rs':
                factor = 1;
                break;
            case 'TA':
            case 'HA':
            case 'T1':
            case 'T2':
            case 'VB':
                factor = 100;
                break;
            case 'H1':
            case 'H2':
                factor = 1000;
                break;
            default:
                factor = 1;
                break;
        }
        return this.getMin(data, factor);
    }

    static getMin(data, factor) {
        let min = Number.MAX_VALUE;
        let element;
        for (let index = 0; index < data.length; index++) {
            element = data[index].y;
            if (element / factor < min) min = element / factor;
        }
        return min;
    }


    static parseData(idVar, data) {
        let factor;
        switch (idVar) {
            case 'Rd':
            case 'Rs':
                factor = 1;
                break;
            case 'TA':
            case 'HA':
            case 'T1':
            case 'T2':
            case 'VB':
                factor = 100;
                break;
            case 'H1':
            case 'H2':
                factor = 1000;
                break;
            default:
                factor = 1;
                break;
        }
        return this.getParsedData(idVar,data, factor);
    }

    static getParsedData(idVar,data, factor) {
        let completo = [];
        let radiationDataList = [];
        let objeto, x, y;
        let dataParsed = {};
        for (let index = 0; index < data.length; index++) {
            objeto = data[index];
            x = objeto.x;
            y = objeto.y;
            dataParsed = {
                "x": x,
                "y": y / factor
            };
            radiationDataList.push(dataParsed);
        }
        let elemento = {};
        elemento.id = idVar;
        elemento.data = radiationDataList;
        completo.push(elemento);
        return completo;
    }
}
export default Utils;